.asset-row-wrapper {
  padding: 0.5% 0 0.5% 0;
  width: 98%;
  min-height: 7%;
  flex-wrap: nowrap;
}

@media only screen and (orientation: portrait) {
  .asset-row-wrapper {
    min-height: unset;
    width: 95%;
  }
}

.asset-row {
  padding: 0.5% 2% 0.5% 1%;
  width: 97%;
  height: 90%;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.asset-row-wrapper:hover {

}

.asset-row:hover {
  background-color: #91cbc2;
}

.asset-item-value {
  width: 40%;
  margin-right: 2%;
}

.asset-item-value-input {
  user-select: text;
  -webkit-user-select: text;
}

.asset-item-value-input,
.asset-item-value input {
  text-align: center;
  border-style: solid;
  border-color: #8da7be;
  border-width: 2px;
  outline: none;
  width: 100%;
  padding: 2% 0;
  border-radius: 4px;
  overflow: hidden;
}

.asset-item-value-input:disabled,
.asset-item-value input:disabled {
  background-color: #F0F8FF;
}

.asset-item-value-input:focus,
.asset-item-value input:focus {
  border-style: dashed;
}

.asset-item-value-input:active,
.asset-item-value input:active {
  border-style: solid;
  border-color: #4f5a6b;
}

.asset-item-name {
  width: 45%;
  min-width: 30%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: small;
}

@media only screen and (min-width: 2400px) {
  .asset-item-value-input {
    font-size: xx-large;
  }
}

@media only screen and (orientation: portrait) and (min-width: 1000px) {

  .asset-item-value-input {
    font-size: x-large;
  }

}

@media only screen and (max-width: 900px) {

  .asset-item-value-input {
    font-size: small;
  }

}

