body, button {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

html, body, .root {
  height: 100%;
  margin: 0;
  min-width: 350px;
}

html {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

.root:has(.modal-window) {
  overflow-y: hidden;
}

button {
  cursor: pointer;
}

input[disabled] {
  cursor: not-allowed;
}

* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@media only screen and (orientation: portrait) and (min-width: 1000px) {
  input {
    font-size: x-large !important;
  }
}

@media only screen and (min-width: 2400px) {
  input, a {
    font-size: xx-large !important;
  }
}

