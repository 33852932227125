.ccxt-logo {
    color: white;
}

.asset-item-buttons-container--integration-logo--binance:hover {
  background-color: #181A20;
  border-radius: 5px;
  padding: 0 1%;
  display: flex;
  width: 100%;
  fill: #F3BA2F;
}

.binance-logo {
  background-color: #181A20;
  border-radius: 5px;
  padding: 10%;
}

.asset-item-buttons-container--integration-logo--okx:hover {
  background-color: #121212;
  border-radius: 5px;
  padding: 0 1%;
  display: flex;
  width: 100%;
}

.okx-logo {
  background-color: #121212;
  border-radius: 5px;
  padding: 10%;
}

.asset-item-buttons-container--integration-logo--monobank:hover {
  fill: #f6f6f6;
  background-color: #191a1e;
  border-radius: 5px;
  padding: 0 1%;
  display: flex;
  width: 30%;
}

.asset-item-buttons-container--integration-logo--wide {
  width: 10%;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  cursor: default;
  font-family: 'Lucida Console', monospace;
  fill: #2D3142;
  height: 90%;
}

.asset-item-buttons-container--integration-logo--wide:hover {
  width: 30%;
  display: flex;
}

@media screen and (max-width: 800px) and (orientation: portrait) {
  .asset-item-buttons-container--integration-logo--wide:hover {
    padding: 1% 0;
  }
}

.asset-item-buttons-container--integration-logo--wide:hover .asset-item-buttons-container--integration-name {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 800px) {
  .asset-item-buttons-container--integration-logo--wide:hover .asset-item-buttons-container--integration-name .okx-logo-img {
    width: 45%;
  }
}

.asset-item-buttons-container--integration-logo--wide:hover .asset-item-buttons-container--integration-logo--short,
.asset-item-buttons-container--integration-logo--wide:hover .asset-item-buttons-container--integration-logo--container {
  display: none;
}


.asset-item-buttons-container--integration-name {
  display: none;
}

.asset-item-buttons-container--integration-logo--container {
  display: flex;
  justify-content: flex-end;
}

.asset-item-buttons-container--integration-logo--short {
  width: 100%;
  height: 100%;
  border-radius: 15%;
}

.asset-item-buttons-container--integration-logo--name {
    background-color: #2D3142;
    color: white;
    padding: 1%;
    border-radius: 5px;
    font-weight: bold;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.asset-item-buttons-container--integration-logo--img {
  align-items: center;
}

.asset-item-buttons-container--integration-logo--img,
.asset-item-buttons-container--ccxt-integration-img {
  height: 100%;
}

.asset-item-buttons-container--ccxt-integration-img img {
  border-radius: 5px;
  height: 70%;
}

.asset-item-buttons-container--integration-metamask {
  width: 100%;
}

@media only screen and (max-width: 1100px) {

  .asset-item-buttons-container--integration-logo--wide {
    width: 9%;
  }
}

@media only screen and (max-width: 970px) {

  .asset-item-buttons-container--integration-logo--wide {
    width: 10%;
    min-width: 7%;
  }
}

@media only screen and (max-width: 800px) {

  .asset-item-buttons-container--integration-logo--wide {
    width: 8%;
  }

}

@media only screen and (max-width: 800px) and (orientation: landscape) {

  .asset-item-buttons-container--integration-logo--short {
    /*width: 60%;*/
  }

  .asset-item-buttons-container--integration-logo--container {
    width: 40%;
  }

}

@media only screen and (max-width: 700px) {

  .asset-item-buttons-container--integration-logo--wide {
    width: 5%;
    /*min-width: 9%;*/
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape) {

  .asset-item-buttons-container--integration-logo--short {
    /*width: 60%;*/
  }

  .asset-item-buttons-container--integration-logo--monobank.asset-item-buttons-container--integration-logo--wide {
    width: 10%;
  }

  .asset-item-buttons-container--integration-logo--monobank.asset-item-buttons-container--integration-logo--wide:hover {
    width: 30%;
  }

}

@media only screen and (orientation: portrait) {
  .asset-item-buttons-container--integration-logo--wide {
    width: 5%;
  }
}
