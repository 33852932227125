.integration-logo--binance {
  fill: #f3ba2f;
  background-color: #181A20;
}

.integration-settings .integration-logo--okx {
  width: 50%;
  height: 50%;
}

.integration-logo.integration-logo--okx {
  background-color: #121212;
  height: 30%;
  width: 40%;
}

@media screen and (orientation: portrait) {
  .integration-logo.integration-logo--okx {
    height: 30%;
  }
}

.okx-integration-logo {
  width: 90%;
}

.integration-logo--monobank {
  fill: #f6f6f6;
  background-color: #191a1e;
  padding: 2%;
}

.integration {
  position: relative;
  width: 100%;
  height: 20%;
}

.integration-settings .integration.integration-enabled,
.integration.integration-enabled {
  padding-left: 35px;
  width: calc(100% - 35px);
}

.integration-settings .integration.integration-enabled,
.integration.integration-enabled {
  padding-left: 35px;
  width: calc(100% - 35px);
}

.integration-logo {
  width: 60%;
  border-radius: 5px;
  padding: 3%;
}

.integration-settings .integration.integration-enabled .integration-logo,
.integration.integration-enabled .integration-logo {
  margin-left: auto;
}

.integration-enabled-corner {
  margin: 0 0 auto auto;
  border-top: 35px solid #608A32;
  border-left: 35px solid transparent;
}

.integration-enabled-corner-text {
  position: absolute;
  text-align: center;
  top: 1%;
  right: 1%;
}

@media screen and (min-width: 1800px), screen and (max-height: 1200px) {
  .integration-settings .integration.integration-enabled,
  .integration.integration-enabled {
    padding-left: 70px;
    width: calc(100% - 70px);
  }

  .integration-enabled-corner {
    border-top-width: 70px;
    border-left-width: 70px;
  }

}

@media screen and (min-height: 1200px), screen and (max-height: 900px) {
  .integration-settings .integration.integration-enabled,
  .integration.integration-enabled {
    padding-left: 50px;
    width: calc(100% - 50px);
  }

  .integration-enabled-corner {
    border-top-width: 50px;
    border-left-width: 50px;
  }

}

@media screen and (min-height: 900px) {
  .integration-settings .integration.integration-enabled,
  .integration.integration-enabled {
    padding-left: 75px;
    width: calc(100% - 75px);
  }

  .integration-enabled-corner {
    border-top-width: 75px;
    border-left-width: 75px;
  }

}

@media only screen and (orientation: portrait) {

  .integration-settings .integration-logo,
  .integration-settings .integration.integration-enabled .integration-logo {
    height: 60%;
  }

  .integration-settings .integration.integration-enabled .integration-logo,
  .integration.integration-enabled .integration-logo {
    margin-left: 0;
  }

  .integration-logo {
    width: 80%;
  }

}

@media only screen and (max-width: 800px) {

  .new-asset-choose-buttons .integration-logo {
    position: absolute;
    width: 80%;
    height: 30%;
    left: 7%;
  }

  .okx-integration-logo {
    width: 60%;
    height: 150%;
  }
}

@media only screen and (max-width: 800px) and (orientation: portrait) {

  .okx-integration-logo {
    width: 60%;
    height: 100%;
  }
}

