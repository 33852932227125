.first-page-stats-box {
  width: 62%;
  height: 100%;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 800px) {

  .first-page-stats-box {
    width: 100%;
    min-height: 100%;
  }

}

@media only screen and (orientation: portrait) {

  .first-page-stats-box {
    width: 100%;
    min-height: 80%;
  }

}

@media only screen and (orientation: landscape) {

    .first-page-stats-box {
        padding-right: 1%;
    }

}
