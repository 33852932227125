.st1, .st6 {
  fill: #e4761b;
  stroke: #e4761b;
  stroke-linecap: round;
  stroke-linejoin: round
}

.st6 {
  fill: #f6851b;
  stroke: #f6851b
}

.metamask-logo {
  height: 100%;
  border-radius: 5px;
  background-color: #25272a;
}

@media only screen and (orientation: portrait) {

  .metamask-logo {
    height: 6vw;
  }
}
