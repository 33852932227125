.button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #3e3e3e;
  box-shadow: 1px 1px 3px 0 #3e3e3e;
  border-radius: 3px;
  font-weight: 500;
}

.button:hover {
  opacity: 0.8;
}

.button:active {
  opacity: 0.5;
}

@media only screen and (orientation: portrait) and (min-width: 1000px) {
  .button {
    font-size: xxx-large !important;
  }
}

@media only screen and (orientation: portrait) and (max-width: 1000px) {
  .button {
    font-size: x-large !important;
  }
}

@media only screen and (min-width: 2400px) {
  .button {
    font-size: xx-large !important;
  }
}

@media only screen and (min-width: 3400px) {
  .button {
    font-size: xxx-large !important;
  }
}

