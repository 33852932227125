.modal-window-box {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.modal-window-shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #2e3532;
  opacity: 0.7;
  z-index: 1;
}

.modal-window {
  position: absolute;
  z-index: 2;
  justify-content: space-between;
  border: 2px solid #3e3e3e;
  box-shadow: 5px 5px 20px 0;
  border-radius: 5px;
}

.modal-window-large {
  width: 60%;
  height: 70%;
  left: 20%;
  top: 10%;
}

.modal-window-small {
  width: 40%;
  height: 40%;
  left: 30%;
  top: 20%;
}

.modal-window-long {
  height: 70%;
  top: 10%;
}

.modal-window-header {
  width: 100%;
  justify-content: end;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0 0 7px 0 #35605a;
}

.modal-window-header-centered {
  justify-content: center;
}

.modal-window-header-small {
  height: 10%;
}

.modal-window-header-large {
  height: 6%;
}

.modal-window-title {
  height: 100%;
  width: 90%;
  font-weight: 900;
}

.modal-window-close-button {
  width: 5%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-window-bottom-small {
  padding: 4%;
  height: 10%;
}

.modal-window-bottom-large {
  padding-top: 1%;
  padding-bottom: 2%;
  height: 10%;
}


@media only screen and (orientation: portrait) and (max-width: 1100px) {

  .modal-window-close-button {
    width: 7%;
  }

  .modal-window-small {
    width: 80%;
    height: 50%;
    left: 10%;
    top: 10%;
  }

}

@media only screen and (orientation: portrait) and (max-width: 800px) {

  .modal-window-small {
    width: 70%;
  }
}

@media only screen and (max-width: 900px) {

  .modal-window-close-button {
    width: 7%;
  }

  .modal-window-large {
    width: 80%;
    height: 80%;
    left: 10%;
    top: 10%;
  }

}

@media only screen and (max-width: 800px) {

  .modal-window-small {
    width: 60%;
    height: 50%;
    left: 20%;
    top: 10%;
  }

}

@media only screen and (max-width: 500px) {

  .modal-window-close-button {
    width: 15%;
  }

  .modal-window-large {
    width: 90%;
    height: 90%;
    left: 5%;
    top: 5%;
  }

  .modal-window-small {
    width: 80%;
    height: 60%;
    left: 10%;
    top: 10%;
  }

}

@media screen and (orientation: portrait) {

  .modal-window-large {
    width: 80%;
    left: 10%;
    top: 10%;
  }

}

@media only screen and (max-width: 500px) and (orientation: portrait) {
  .modal-window-large {
    top: 5%;
  }
}

@media only screen and (max-height: 700px) {

  .modal-window-large {
    height: 90%;
    top: 5%;
  }

}
