.dial-container {
    width: 40%;
    height: 100%;
    display: grid;
    grid-auto-rows: 20%;
    grid-template-columns: 31.3% 31.3% 31.3%;
    grid-column-gap: 3%;
    grid-row-gap: 5%;
}

.dial-button {
    font-size: xx-large;
}

@media screen and (orientation: portrait) {
    .dial-container {
        width: 90%;
        grid-auto-rows: 15%;
    }

}

@media screen and (orientation: portrait) and (min-height: 1200px) {
    .dial-button {
        font-size: xxx-large;
    }

}

@media screen and (orientation: landscape) and (max-width: 1100px) {
    .dial-button {
        font-size: x-large;
    }

}
