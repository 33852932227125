.application-box {
  width: 100%;
  height: 100%;
  display: flex;
}

.application-first-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.assets-panel {
  margin: 0.5% 0.5% 0.5% 1%;
  width: 35%;
  height: 98%;
  justify-content: flex-start;
  overflow-y: auto;
}

@media only screen and (orientation: portrait) {
  .assets-panel {
    overflow-y: unset;
    height: unset;
  }

  .application-first-page:has(.modal-window-shadow) {
    overflow: hidden;
  }
}

@media only screen and (max-width: 800px), screen and (orientation: portrait) {

  .application-first-page {
    flex-flow: column;
  }

  .assets-panel {
    width: 98%;
  }

}
