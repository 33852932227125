.startup-loading-box {
  width: 50%;
  height: 80%;
  /*position: absolute;*/
  justify-content: space-evenly;
  padding: 1%;
}

.loading-label {
  padding: 1%;
}

@media only screen and (max-width: 800px) {

  .startup-loading-box {
    width: 80%;
  }

}

@media only screen and (orientation: portrait) {

  .startup-loading-box {
    width: 90%;
  }

}
