.negative-button {
  font-size: 150%;
  text-align: center;
  outline: none;
  color: #D00000;
  background-color: #F0F8FF;
  border-color: #8DA7BE;
  border-radius: 2px;
}

.negative-button:hover,
.negative-button:active {
  background-color: #D00000;
  color: #2D3142;
}

.negative-button:active {
  margin: 1px 0 0 1px;
}


@media only screen and (max-width: 900px) {

  .negative-button {
    font-size: 100%;
  }

}
