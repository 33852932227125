.neutral-button,
.neutral-button.button {
  font-size: 150%;
  text-align: center;
  outline: none;
  color: #2D3142;
  background-color: #ebf0f5;
  border-color: #8DA7BE;
  border-radius: 2px;
}

.neutral-button:hover,
.neutral-button.button:hover,
.neutral-button:active {
  background-color: #ffffff;
  color: #2D3142;
  opacity: 1;
}

.neutral-button:active {
  margin: 1px 0 0 1px;
}

@media only screen and (max-width: 900px) {

  .neutral-button,
  .neutral-button.button {
    font-size: 100%;
  }

}
