.text-label {
  cursor: default;
}

input.invalid {
    border: dashed #D00000;
}

.pad {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 3px 0 #6f9f98;
  border-radius: 4px;
  opacity: 0.9;
}

.pad:hover {
  opacity: 1;
  box-shadow: 0 0 3px 2px #78c9bc;
  /*box-shadow: 2px 2px 5px 2px #6f9f98;*/
}

.pad:active {
  opacity: 0.9;
}

.disabled {
  cursor: not-allowed;
}

@media only screen and (min-width: 1000px) {
  .pad {
    font-size: large;
  }
}

@media only screen and (min-width: 1500px) {
  .pad {
    font-size: x-large;
  }
}

@media only screen and (min-width: 2000px) {
  .pad {
    font-size: xx-large;
  }
}

.flex-box {
  display: flex;
  justify-content: space-evenly;
}

.flex-box-centered {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.flex-box-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-direction-row {
  flex-direction: row;
}

.flex-centered {
  align-items: center;
}

.border-themed-color {
  background-color: #2e3532;
}

.background-themed-color {
  background-color: #35605a;
}

.layer-0-themed-color {
  background-color: #508079;
}

.layer-1-themed-color {
  background-color: #6f9f98;
}

.layer-2-themed-color {
  background-color: #8cbeb7;
}

.layer-3-themed-color {
  background-color: #a9ddd6;
}

.clickable {
  cursor: pointer;
}

.application {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
}

div {
  user-select: none;
  -webkit-user-select: none;
}

@media only screen and (orientation: portrait) {
  .application {
    justify-content: unset;
  }

  .application:has(.startup-loading-box) {
    /*justify-content: center;*/
  }

}

@media only screen and (min-width: 2400px) {
  .text-label {
    font-size: xx-large !important;
  }
}

@media only screen and (max-width: 1200px) {
  .text-label {
    /*font-size: large !important;*/
  }
}

@media only screen and (max-width: 800px) {

  .application {
    height: auto;
    min-height: 100%;
  }

}

@media only screen and (orientation: portrait) and (min-width: 1000px) {
  .text-label {
    font-size: x-large !important;
  }
}

@media only screen and (max-width: 600px) {

  .text-label {
    font-size: small;
  }

}

