.add-new-asset-row {
  margin: 1%;
  width: 95%;
  height: 5%;
  justify-content: space-between;
}

.add-new-asset-button {
  width: 10%;
  height: 90%;
  /*margin-left: 15%;*/
  font-weight: bold;
}

.show-configs-window-button {
  width: 10%;
  height: 90%;
  fill: darkslategrey;
}

.add-new-asset-button,
.show-configs-window-button {
  padding: 0.5% 0;
}

@media screen and (orientation: portrait) {
  .add-new-asset-row {
    height: unset;
  }
}

@media only screen and (max-width: 800px) {

  .add-new-asset-button {
    padding: 1% 0;
  }

  .show-configs-window-button {
    padding: 0.3% 0;
  }
}

@media only screen and (max-width: 680px) {

  .show-configs-window-button {
    padding: 0.5% 0;
  }
}

@media only screen and (max-width: 620px) {

  .show-configs-window-button {
    padding: 0.7% 0;
  }
}

@media only screen and (max-width: 520px) {

  .show-configs-window-button {
    padding: 1% 0;
  }

  .add-new-asset-button {
    font-size: 140% !important;
  }

}
