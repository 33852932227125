.setting-unit {
  align-items: start;
}

.setting-box {
  padding: 2%;
  width: 95%;
  align-items: start;
}

.setting-row {
  padding-bottom: 2%;
  width: 100%;
  justify-content: start;
}

.settings-checkbox-row {
  padding-bottom: 1%;
}

.setting-row-progress {
  margin: 1%;
}

.integration-settings {
  display: grid;
  grid-auto-rows: 33%;
  grid-template-columns: 49% 49%;
  grid-column-gap: 1%;
  grid-row-gap: 4%;
  margin-bottom: 1%;
}

.integration-settings .integration {
  width: 100%;
  height: 100%;
  position: relative;
}

.integration-settings .integration .integration-logo {
  margin: 1%;
}

.setting-label {
  display: flex;
  margin: 3% 0 1% 1%;
}

.settings-go-back-row {
  display: flex;
  justify-content: flex-start;
  padding: 2%;
  width: 95%;
  margin-bottom: 3%;
  font-weight: bold;
}

.choose-setting--row {
  width: 100%;
  height: 10%;
  justify-content: space-between;
}

.choose-setting--setting {
  width: 49.5%;
}

.export-text-area {
  width: 100%;
  height: 90px;
}

.setting-separate-line {
  margin-bottom: 1%;
}

@media only screen and (orientation: portrait) {

  .integration-settings .integration-logo--okx {
    height: 60%;
    width: 56%;
  }

  .integration-settings {
    grid-template-columns: 100%;
    grid-auto-rows: 18%;
  }

}


@media only screen and (max-width: 800px) {

  .integration-settings .integration-logo--okx {
    height: 60%;
    width: 56%;
  }

  .integration-settings {
    grid-template-columns: 100%;
    grid-auto-rows: 18%;
  }

  .setting-label {
    margin: 5% 0;
  }

}
