.positive-button {
  font-size: 150%;
  text-align: center;
  outline: none;
  color: #6f9f98;
  background-color: #F0F8FF;
  border-color: #8DA7BE;
  border-radius: 2px;
}

.positive-button:hover,
.positive-button:active {
  background-color: #9fec4e;
  color: #fff;
}

.positive-button:active {
  margin: 1px 0 0 1px;
}

@media only screen and (max-width: 900px) {

  .positive-button {
    font-size: 130%;
  }

}

@media only screen and (max-width: 520px) {

  .positive-button {
    font-size: 100%;
  }

}
