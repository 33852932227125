.new-asset-choose-button-image-text {
  font-weight: bold;
  width: 100%;
  font-size: 350%;
  background-color: #F6AE2D;
  border-radius: 47%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-flow: column;
}

.new-asset-choose-button-image-icon {
  height: unset;
}

.new-asset-controls-box {
  height: 100%;
  width: 100%;
}

.new-asset-choose-box {
  width: 40%;
  height: 100%;
}

.new-asset-choose-title {
  height: 5%;
  width: 90%;
}

.new-asset-choose-buttons {
  width: 100%;
  height: 85%;
  flex-wrap: wrap;
}

.new-asset-choose-button {
  height: 40%;
  width: 30%;
}

.new-asset-choose-button-image {
  width: 90%;
  height: 70%;
}

.new-asset-choose-button-symbol {
  height: 55%;
  width: 90%;
}

@media only screen and (min-width: 2400px) {
  .new-asset-choose-button-name {
    font-size: xx-large;
  }
}

@media only screen and (max-width: 800px) {

  .new-asset-choose-button {
    height: 30%;
    width: 40%;
  }

  .new-asset-choose-button-image-text {
    font-size: 150%;
  }

  .new-asset-choose-button-image-icon {
    height: 100%;
  }

}

@media only screen and (max-width: 520px), screen and (orientation: portrait) {

  .new-asset-choose-button-symbol {
    height: 55%;
    width: 40%;
  }

  .new-asset-choose-buttons {
    overflow: auto;
  }

  .new-asset-choose-button {
    height: 12%;
    width: 90%;
    flex-flow: row;
    justify-content: space-evenly;
  }

  .new-asset-choose-button-image {
    width: 40%;
  }

  .new-asset-choose-button-image-icon {
    width: unset;
    height: 100%;
  }

  .new-asset-choose-box {
    width: 50%;
  }

}
